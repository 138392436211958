<template>
  <div class="users">
    <pageHeaderView></pageHeaderView>
    <Card class="content">
      <div class="card-header">
        <div class="card-header-text">全部用户列表</div>
        <div class="card-header-divider"></div>
      </div>
      <div class="box-header">
        <div class="box-filters">
          <Select v-model="filter.Permission" placeholder="请选择">
            <Option
              v-for="(p,pIdx) in $common.getEnumSelect($common.permissions)"
              :key="pIdx"
              :value="parseInt(p.key)">{{p.value}}</Option>
          </Select>
        </div>
        <div class="box-tools">
          <div
            style="display: inline-block; margin-right: 15px"
            @mouseenter="toggleSearch(true)"
            @mouseleave="toggleSearch(false)"
          >
            <Select
              v-model="searchBy"
              class="table-search"
              :class="{ on: tableSearchOn || searchMode || searchBy }"
            >
              <Option
                :value="col.searchKey ? col.searchKey : col.key"
                v-for="col in columns.filter((c) => {
                  return c.title && c.searchable;
                })"
                :key="col.key"
                >{{ col.title }}</Option
              >
            </Select>&nbsp;
            <Input
              v-model="keyword"
              ref="tableSearch"
              placeholder="搜索关键字"
              @on-focus="toggleSearchMode"
              @on-blur="toggleSearchMode"
              class="table-search"
              :class="{ on: tableSearchOn || searchMode || keyword }"
            ></Input
            >&nbsp;
            <Button type="default" icon="ios-search" @click="searchRemote"></Button>
          </div>
          <Button-group>
            <Button type="default" icon="md-person-add" @click="toggleNewForm"
              >新增</Button
            >
            <Button type="default" icon="md-refresh" @click="refreshList"
              >刷新</Button
            >
          </Button-group>
        </div>
      </div>
      <div class="box-body">
        <Table
          stripe
          :columns="columns"
          :data="data"
          ref="table"
          @on-selection-change="selectRowChange"
        ></Table>
        <div style="margin: 10px; overflow: hidden">
          <Button
            type="primary"
            size="large"
            @click="exportData"
            :disabled="!rowSelected"
            ><Icon type="ios-download-outline"></Icon> 导出原始数据</Button
          >
          <div style="float: right">
            <Page
              :total="totalCount"
              :page-size-opts="[10, 20, 50, 100]"
              @on-change="changePage"
              @on-page-size-change="changePageSize"
              :current="pageCurrent"
              show-sizer
            ></Page>
          </div>
        </div>
        <Modal v-model="modalDelete" width="360">
          <p slot="header" style="color: #f60; text-align: center">
            <Icon type="information-circled"></Icon>
            <span>删除确认</span>
          </p>
          <div style="text-align: center">
            <p>是否继续删除？</p>
          </div>
          <div slot="footer">
            <Button
              type="error"
              size="large"
              long
              :loading="modal_loading"
              @click="del"
              >删除</Button
            >
          </div>
        </Modal>
        <Modal v-model="modalUnBindWx" width="360">
          <p slot="header" style="color: #f60; text-align: center">
            <Icon type="information-circled"></Icon>
            <span>解除绑定确认</span>
          </p>
          <div style="text-align: center">
            <p>是否继续解除微信绑定？</p>
          </div>
          <div slot="footer">
            <Button
              type="error"
              size="large"
              long
              :loading="modal_loading"
              @click="unbindWx"
              >解绑</Button
            >
          </div>
        </Modal>
        <Modal v-model="modalDisable" width="360" @on-cancel="cancelDisable">
          <p slot="header" style="color: #f60; text-align: center">
            <Icon type="information-circled"></Icon>
            <span>禁用用户操作</span>
          </p>
          <div style="text-align: center">
            <p>是否禁用用户账户？</p>
            <p>禁用后用户将暂时无法登陆。</p>
          </div>
          <div slot="footer">
            <Button
              type="error"
              size="large"
              long
              :loading="modal_loading"
              @click="disableUser"
              >禁用</Button
            >
          </div>
        </Modal>
        <Modal v-model="modalDetail" v-if="userSelected">
          <ul class="list-group list-group-unbordered">
            <li class="list-group-item">
              <b>姓名</b> <span class="">{{ userSelected.Name }}</span>
            </li>
            <li class="list-group-item">
              <b>性别</b>
              <span
                ><Tag
                  :color="$common.genderColor[userSelected.IsMale ? '1' : '0']"
                  >{{ $common.gender[userSelected.IsMale ? "1" : "0"] }}</Tag
                ></span
              >
            </li>
            <li class="list-group-item">
              <b>生日</b>
              <span class="">{{
                $moment(userSelected.Birthday).format("YYYY-MM-DD")
              }}</span>
            </li>
            <li class="list-group-item">
              <b>用户登录名</b>
              <span class="">{{ userSelected.Username }}</span>
            </li>
            <li class="list-group-item">
              <b>用户权限</b>
              <span class=""
                ><Tag
                  v-for="(perm, permIdx) in getPermissions()"
                  :key="permIdx"
                  >{{ perm }}</Tag
                ></span
              >
            </li>
            <li class="list-group-item">
              <b>账号状态</b>
              <span
                ><Tag
                  :color="
                    $common.genderColor[userSelected.IsEnable ? '1' : '0']
                  "
                  >{{ getEnableText() }}</Tag
                ></span
              >
            </li>
            <li class="list-group-item">
              <b>身份证号</b>
              <span class="">{{ showIdNumber(userSelected.IdNumber) }}</span>
            </li>
            <li class="list-group-item">
              <b>手机号码</b>
              <span class="">
                <Tooltip :content="userSelected.MobilePhoneNumber">
                  {{ getEncodedMobile(userSelected.MobilePhoneNumber) }}
                </Tooltip>
              </span>
            </li>
            <li class="list-group-item">
              <b>IC卡编码</b> <span class="">{{ userSelected.IcCardId }}</span>
            </li>
            <li class="list-group-item">
              <b>微信openId</b>&nbsp;
              <span class="">
                {{ userSelected.Wechat }}
                <Button v-if="userSelected.Wechat" type="error" ghost size="small" @click="modalUnBindWx=true">解绑</Button>
              </span>
            </li>
            <li class="list-group-item">
              <b>邮箱</b> <span class="">{{ userSelected.Email }}</span>
            </li>
            <li class="list-group-item">
              <b>注册日期</b>
              <span class="">{{
                $moment(userSelected.CreationTime).format("YYYY-MM-DD")
              }}</span>
            </li>
            <li class="list-group-item">
              <b>标签</b>
              <span class="">
                <Tag v-for="(tag,index) in userSelected.UserTags" :key="index" type="border" color="primary">{{tag.TagName}}</Tag>
                <Button size="small"
                  style="font-size:13px; background-color:#238bc5; color:#fff; border-radius: 4px; width:50px; border:none; cursor: pointer;"
                  @click="toggleshowTage">添加+</Button>
              </span>
            </li>
            <li class="list-group-item">
              <b>回访记录</b>
              <span class="">
                <button
                 style="background-color:#268b97; color:#fff; border-radius: 4px; width:80px; border:none; cursor: pointer;"
                 @click="toggleshowReview">点击查看</button>
              </span>
            </li>
          </ul>
        </Modal>
        <userNewForm
          :show="showUserNewForm"
          @close="closeNewForm"
          @refresh="refreshList"
        ></userNewForm>
        <userPwdForm
          :user="userSelected"
          :show="showUserPwdForm"
          @close="closePwdForm"
          @refresh="refreshList"
        ></userPwdForm>
        <userEditForm
          :user="userSelected"
          :show="showUserEditForm"
          @close="closeEditForm"
          @refresh="refreshList"
        ></userEditForm>
        <user-review-records
          :dataSelected="UserReview"
          :show="showReview"
          @close="closeshowReview"
        ></user-review-records>
        <user-tags-add
          :tagdata="userSelected"
          :show="showTage"
          @close="closeshowTage"
        ></user-tags-add>
      </div>
    </Card>
    <router-view :data="userSelected" @refresh="refreshList"></router-view>
  </div>
</template>

<script>
import pageHeaderView from '../public/PageHeader'
import userNewForm from './UserNewInModal'
import userPwdForm from './UserPwd'
import userEditForm from './UserEditInModal'
import UserReviewRecords from './UserReviewRecords'
import UserTagsAdd from './UserTagsAdd'

export default {
  name: 'users',
  components: {
    pageHeaderView,
    //boxOverLay,
    userNewForm,
    userPwdForm,
    userEditForm,
    UserReviewRecords,
    UserTagsAdd,
  },
  data() {
    return {
      showUserNewForm: false,
      showUserPwdForm: false,
      showUserEditForm: false,
      loading: false,
      userSelected: null,
      modal_loading: false,
      modalDelete: false,
      modalUnBindWx: false,
      modalDisable: false,
      modalDetail: false,
      rowSelected: false,
      rowSelectedList: [],
      pageCurrent: 1,
      pageSize: 10,
      totalCount: 0,
      keyword: '',
      searchBy: '',
      searchMode: false,
      tableSearchOn: false,
      columns: [
        { type: 'selection', width: 60, align: 'center' },
        { title: '用户名', key: 'Username', sortable: true, searchable: true },
        {
          title: '姓名',
          key: 'Name',
          width: 120,
          ellipsis: true,
          sortable: true,
          searchable: true,
          sortMethod: (a, b, type) => {
            if (type === 'asc') {
              return a.localeCompare(b, 'zh-CN')
            } else {
              return b.localeCompare(a, 'zh-CN')
            }
          },
        },
        {
          title: '性别',
          key: 'IsMale',
          width: 80,
          sortable: true,
          render: (h, params) => {
            return h(
              'span',
              {},
              this.$common.gender[params.row.IsMale ? '1' : '0']
            )
          },
        },
        {
          title: '标签',
          key: 'UserTags',
          // width: 200,
          align: 'center',
          ellipsis: true,
          tooltip: true,
          sortable: true,
          searchable: true,
          render: (h, params) =>{
            return h ('span', [
              h (
                'div',
                this.data[params.index].UserTags.map(item => {
                  return h('tag',
                  {
                    style: {border: '1px solid #268b97',height: '18px','line-height': '17px'}
                  },
                  item.TagName)
                })
              ),
            ])
          }
        },
        // {
        //   title: '身份证号',
        //   key: 'IdNumber',
        //   sortable: true,
        //   searchable: true,
        //   render: (h, params) => {
        //     return h('span', {}, this.showIdNumber(params.row.IdNumber))
        //   },
        // },
        {
          title: '手机号',
          key: 'MobilePhoneNumber',
          sortable: true,
          searchable: true,
          render: (h, params) => {
            return h('span', {}, this.getEncodedMobile(params.row.MobilePhoneNumber))
          },
        },
        // { title: '身份证序列号', key: 'IcCardId', sortable: true, searchable: true },
        {
          title: '状态',
          key: 'IsEnable',
          width: 80,
          render: (h, params) => {
            return h('div', [
              h(
                'i-switch',
                {
                  props: {
                    // type: 'ghost',
                    value: params.row.IsEnable,
                    size: 'large',
                  },
                  on: {
                    'on-change': () => {
                      this.userSelected = params.row
                      if (params.row.IsEnable) {
                        this.userSelected.IsEnable = false
                        this.modalDisable = true
                      } else {
                        this.userSelected.IsEnable = true
                        this.disableUser()
                      }
                    },
                  },
                },
                [
                  h(
                    'span',
                    {
                      slot: 'open',
                    },
                    '正常'
                  ),
                  h(
                    'span',
                    {
                      slot: 'close',
                    },
                    '禁用'
                  ),
                ]
              ),
            ])
          },
        },
        {
          title: '操作',
          key: 'action',
          fixed: 'right',
          width: 160,
          render: (h, params) => {
            return h('Button-group', [
              h(
                'Button',
                {
                  props: {
                    type: 'default',
                    size: 'small',
                  },
                  style: {},
                  on: {
                    click: () => {
                      this.userSelected = params.row
                      this.modalDetail = true
                    },
                  },
                },
                '详情'
              ),
              h(
                'Button',
                {
                  props: {
                    type: 'default',
                    size: 'small',
                  },
                  style: {},
                  on: {
                    click: () => {
                      this.userSelected = params.row
                      this.toggleEditForm()
                    },
                  },
                },
                '修改'
              ),
              h(
                'Button',
                {
                  props: {
                    type: 'default',
                    size: 'small',
                  },
                  on: {
                    click: () => {
                      this.userSelected = params.row
                      // this.showUserPwdForm = true
                      this.togglePwdForm()
                      // this.$router.push({ path: '/users/pwd/' + params.row.Id })
                    },
                  },
                },
                '改密'
              ),
            ])
          },
        },
      ],
      dataRaw: [],
      data: [],
      dataViewPage: [],
      filter: {
        Permission: 1,
        Name: '',
        Username: '',
        MobilePhoneNumber: '',
        IdNumber: ''
      },
      showReview: false,
      UserReview: {
        Name: '',
        Id: ''
      },
      showTage: false,
      // taglist: {},
      // userTage: {
      //   Id: '',
      // }
    }
  },
  watch: {
    'filter.Permission': function () {
      this.pageCurrent = 1
      this.totalCount = 0
      this.pageSize = 10
      this.initData()
    },
    pageCurrent: function () {
      this.initData()
    },
    pageSize: function () {
      this.initData()
    },
    keyword: function (newVal) {
      if (this.searchBy) {
        this.filter[this.searchBy] = newVal
      }
    }
  },
  mounted() {
    this.$store.dispatch('setPageStore', {
      pageHeader: '用户列表',
      pageDescr: '全部用户列表',
    })
    this.refreshList()
  },
  methods: {
    toggleNewForm() {
      this.showUserNewForm = true
      // this.$router.push({ path: '/users/new' })
    },
    closeNewForm() {
      this.showUserNewForm = false
    },
    togglePwdForm() {
      this.showUserPwdForm = true
      // this.$router.push({ path: '/users/new' })
    },
    closePwdForm() {
      this.showUserPwdForm = false
    },
    toggleEditForm() {
      this.showUserEditForm = true
      // this.$router.push({ path: '/users/new' })
    },
    closeEditForm() {
      this.showUserEditForm = false
    },
    toggleshowReview() {
      this.showReview = true
      this.UserReview = this.userSelected
      // console.log(this.UserReview)
      // this.$router.push({ path: '/users/new' })
    },
    closeshowReview() {
      this.showReview = false
    },
    toggleshowTage() {
      this.showTage = true
      // this.userTage = this.userSelected
    },
    closeshowTage() {
      this.showTage = false
    },
    getUser(userName) {
      this.userSelected = null
      this.userSelected = this.datatableData.filter((item) => {
        return item.Id === userName
      })[0]
    },
    getPermissions() {
      let perms = []
      if (this.userSelected.Permissions) {
        this.userSelected.Permissions.forEach((p) => {
          perms.push(this.$common.permissions[p.toString()])
        })
      }
      return perms
    },
    getEnableText() {
      var text = this.userSelected.IsEnable ? '已启用' : '已禁用'
      return text
    },
    showIdNumber(id) {
      let idnumber = ''
      if (id) {
        if (id.length === 18) {
          // idnumber = id.substr(0, 6) + '****' + id.substr(14, 18)
          idnumber = id.substr(0, 10) + '****'
        } else if (id.length === 15) {
          idnumber = id.substr(0, 8) + '****'
        }
      }
      return idnumber
    },
    getEncodedMobile (mobile) {
      if (mobile) {
        return mobile.substr(0, mobile.length - 8) + '****' + mobile.substr(mobile.length - 4)
      }
    },
    refreshList() {
      // refreshList()
      this.pageCurrent = 1
      this.pageSize = 10
      this.totalCount = 0
      this.initData()
      this.searchBy = ''
      this.keyword = ''
      this.searchMode = false
      this.tableSearchOn = false
    },
    initData() {
      this.$Spin.show()
      this.$Api
        .Auth(this)
        .getUsersByPermission(this.filter, this.pageCurrent, this.pageSize)
        .then((respBody) => {
          this.$Spin.hide()
          this.dataRaw = respBody.UserList
          this.data = respBody.UserList
          this.totalCount = respBody.TotalCount
        })
        .catch((err) => {
          this.$Spin.hide()
          this.$Notice.error({
            title: '获取用户列表失败!',
            desc: err.message,
          })
        })
    },
    selectRowChange(selection) {
      if (selection.length > 0) {
        this.rowSelected = true
        this.rowSelectedList = selection
      } else {
        this.rowSelected = false
        this.rowSelectedList = []
      }
    },
    changePage(page) {
      this.pageCurrent = page
    },
    changePageSize(pageSize) {
      this.pageSize = pageSize
    },
    exportData() {
      this.$refs.table.exportCsv({
        filename: 'zztdata.csv',
        columns: this.columns,
        data: this.rowSelectedList,
      })
    },
    del() {
      this.modal_loading = true
      setTimeout(() => {
        this.modal_loading = false
        this.modalDelete = false
        this.$Message.success('删除成功')
      }, 2000)
    },
    unbindWx() {
      this.$Spin.show()
      this.modal_loading = true
      this.$Api
        .Auth(this)
        .unbindWx(
          this.userSelected.Username
        )
        .then(() => {
          this.$Spin.hide()
          this.modal_loading = false
          this.modalUnBindWx = false
          this.userSelected.Wechat = null
          this.$Message.success('操作成功！')
        })
        .catch((err) => {
          this.$Spin.hide()
          this.modal_loading = false
          this.modalUnBindWx = false
          this.$Notice.error({
            title: '提交失败！',
            desc: err.message,
          })
        })
    },
    disableUser() {
      this.$Spin.show()
      this.modal_loading = true
      this.$Api
        .Auth(this)
        .changeUserStatus(
          this.userSelected.Username,
          this.userSelected.IsEnable
        )
        .then(() => {
          this.$Spin.hide()
          this.modal_loading = false
          this.modalDisable = false
          this.$Message.success('操作成功！')
        })
        .catch((err) => {
          this.$Spin.hide()
          this.modal_loading = false
          this.modalDisable = false
          this.cancelDisable()
          this.$Notice.error({
            title: '提交失败！',
            desc: err.message,
          })
        })
    },
    cancelDisable() {
      this.userSelected.IsEnable = true
    },
    toggleSearchMode() {
      this.searchMode = !this.searchMode
    },
    toggleSearch(val) {
      this.tableSearchOn = val
    },
    searchRemote () {
      this.pageCurrent = 1
      this.totalCount = 0
      this.initData()
    },
    search() {
      let cols = this.columns.filter((col) => {
        return col.searchable
      })
      this.data = this.dataRaw.filter((item) => {
        if (this.searchBy) {
          let content = this.getSearchQueryContent(item, this.searchBy)
          return content.toString().indexOf(this.keyword) >= 0
        } else {
          let found = false
          for (let i = 0; i < cols.length; i++) {
            let col = cols[i]
            let content = this.getSearchQueryContent(
              item,
              col.searchKey ? col.searchKey : col.key
            )
            if (content && content.toString().indexOf(this.keyword) >= 0) {
              found = true
            }
          }
          return found
        }
      })
      this.pageCurrent = 0
      this.pageCurrent = 1
    },
    getSearchQueryContent(item, key) {
      if (!key) {
        return ''
      }
      let qArr = []
      let qArrTemp = key.split('.')
      qArrTemp.forEach((q) => {
        if (q.indexOf(']')) {
          let subArr = q.replace(/]/g, '').split('[')
          subArr.forEach((sub) => {
            qArr.push(sub)
          })
        } else {
          qArr.push(q)
        }
      })
      let content = item
      qArr.forEach((query) => {
        if (content[query]) {
          content = content[query]
        } else {
          content = ''
        }
      })
      return content
    },
  },
}
</script>

<style lang="less" scoped>
.box-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  .box-filters {
    max-width: 520px;
    min-width: 240px;
  }
  .box-tools {
    flex: 1;
  }
}
</style>
